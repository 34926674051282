import React, { useState, useEffect } from "react";
import $ from "jquery";
import {
  setAcitveCategory,
  setAcitveAllCategory,
  changeInputText,
  changeReportText,
  parseData,
  addObjects,
  deleteObjects,
  test,
  changeTextControls,
  changeRadio,
  changeCheck,
  saveMD5,
  saveMD5controls,
  saveAdditionalMD5,
  createPopupReportList,
  onLoadReport,
  updateGeneratedSentenceText
} from "../../../../store/editorState/actions";
import { connect } from "react-redux";
import { toogleFastInsert } from "../../../../store/fastInsert/actions";
import { workIcons } from "../images/images";
import "./reportEditor.css";
import InsertImgBtn from "./insertImgBtn";
import ImageGallery from "./imageGallery";
import TextInputCustom from "../../../components/textInputCustom";
import FastInsert from "./fastInsert/fastInsert";
import store from "../../../../index";
import md5 from "react-native-md5";
import tmpObj from "../reportEditor/checkModReport";
import { selectCurrentReport } from "../../../../store/leftMenu/actions";
import GlobalSettings from "../../../../settings/GlobalSettings";

const Entities = require('html-entities').XmlEntities;

function ReportEditor(props) {
  const [galleryImgIndex, setGalleryImgIndex] = useState(null);

  useEffect(() => {
    if (store.getState().editorState.metaData[0] !== "") {
      props.saveAdditionalMD5(md5.hex_md5(store.getState().editorState.metaData[0] + store.getState().editorState.metaData[1] + store.getState().editorState.metaData[2]));
    }
    //debugger;
    if (props.templatePK != null && props.editor.reportPK === null) {
      //debugger;
      $.ajax({
        type: "GET",
        url: `${props.url}/examinations/navigation/${
            props.templatePK
            }/template/`,
        contentType: "application/json",
        crossDomain: true,
        headers: {
          "Authorization": `Token ${store.getState().settings.userKey}`
        },
        success: function (data) {
          props.parseData(data);
          let res = props.categoryArray.map(a => ({ ...a }));
          let tmpCategoryArray = {};
          res.map(function (data, index) {
            delete (data.visible);
            return tmpCategoryArray[index] = data;
          });
          props.saveMD5controls(md5.hex_md5(JSON.stringify(tmpCategoryArray)));
          loadGeneratedSentences();
        },
        error: function (data) {
          if (data.status === 401) {
            createPopupReportList(store,
                ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
                "O", {
                  "O": () => {
                    document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                    document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                    document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                    document.location.reload(true);
                  }
                });
          }
          else {
            createPopupReportList(store,
                ["Не удалось получить данные по заключению!", <br />, "Пожалуйста, попробуйте позднее."],
                "O", {});
          }
        }
      });
    }
  }, [props.templatePK, props.editor.reportPK]);

  const loadGeneratedSentences = () => {
    props.categoryArray.forEach((category, index) => {
      if (category.paragraph) {
        category.paragraph.forEach((subparagraph, i) => {
          subparagraph.matrixArray.forEach((arr, arrIndex) => {
            loadGeneratedSentenceText(index, i, arrIndex);
          });
        });
      }
    });
  }

  const copyReport = () => {
    tmpObj.check(() => {
      if (store.getState().editorState.reportPK) {
        let tmp = props.categoryArray.slice(0, props.categoryArray.length - 2);
        let t = tmp.map((levelOne) => {
          return levelOne.paragraph.map((levelTwo) => {
            return levelTwo.matrixArray.map((oneMatrix) => {
              return {
                ...oneMatrix,
                pk: -1,
                values: oneMatrix.values.map((value) => {
                  return {
                    ...value,
                    pk: -1
                  };
                })
              };
            });
          });
        });

        //Object: -create an object before sending
        let tmpObj = {
          ...props.editor.reportData[0],
          date: store.getState().editorState.metaData[0],
          text: store.getState().editorState.generatedText,
          patient_date_of_birth: store.getState().editorState.metaData[2],
          patient_name: store.getState().editorState.metaData[1],
          sentences: t.flat(2),
          template: props.editor.templatePK,
          pk: -1
        };

        $.ajax({
          type: "POST",
          url: `${props.url}/examinations/reports/`,
          contentType: "application/json",
          data: `${JSON.stringify(tmpObj)}`,
          headers: {
            "Authorization": `Token ${props.userKey}`
          },
          crossDomain: true,
          success: function (data) {
            createPopupReportList(store,
                ["Заключение скопировано."],
                "O", {}, "sucsess");
            store.dispatch({
              type: "SELECT_FIRST_REPORT"
            });
            store.dispatch({
              type: "SET_REPORTSDATA",
              payloadReportsData: []
            });
            selectCurrentReport(data.pk, data.pk);
          },
          error: function (data) {
            if (data.status === 401) {
              createPopupReportList(store,
                  ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
                  "O", {
                    "O": () => {
                      document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                      document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                      document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                      document.location.reload(true);
                    }
                  });
            }
            else {
              createPopupReportList(store,
                  ["Не удалось скопировать заключение!", <br />, "Пожалуйста, попробуйте позднее."],
                  "O", {});
            }
          }
        });
      }
      else {
        createPopupReportList(store,
            ["Скопировать можно только уже существующее заключение!"],
            "O", {});
      }
    });
  };

  /**
   * Метод для сохранения репорта
   * Убрал создание лишних объектов
   * Убрал попап о предупреждении сохранения, когда пользователь сам сохраняет.
   */
  const saveReport = (action) => {
    tmpObj.check((PKdata) => {
      let res = props.categoryArray.map(a => ({ ...a }));
      let tmpCategoryArray = {};
      res.map(function (data, index) {
        delete (data.visible);
        return tmpCategoryArray[index] = data;
      });
      props.saveMD5controls(md5.hex_md5(JSON.stringify(tmpCategoryArray)));
      if (props.editor.generatedText !== "") {
        props.saveMD5(md5.hex_md5(props.editor.generatedText));
      }
      selectCurrentReport(props.editor.reportPK, props.editor.reportPK);
      if (action === "download_pdf") {
        getPDFinNewTab(PKdata)
      } else if (action === "download_word") {
        downloadWord(PKdata);
      } else if (action === "share") {
        shareReport(PKdata);
      }
      //tmpObj.serverImage.WWS.onSelectReport();
    }, false, true);
  };

  async function shareReport (pk) {
    try {
      let response = await fetch(`${props.url}/examinations/reports/${pk}/share`,{
        method:'POST',
        headers: {
          "Authorization": `Token ${props.userKey}`,
        }})
      switch (response.status) {
        case 200:
          const data = await response.json();
          const url = `${GlobalSettings.protocolAndHost}/shared?srk=${data.data}`;
          const name = props.editor.metaData[1];
          const date = props.editor.metaData[0];
          createPopupReportList(store,
              [`Скопируйте ссылку ниже для просмотра заключения исследования:`, <br />, `${name} от ${date}`, <br />, url],
              "Ok_Copy",
              { "Copy": () => { navigator.clipboard.writeText(url) } },
                "sucsess");
          return
        case 401:
          createPopupReportList(store,
              ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
              "O", {
                "O": () => {
                  document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.location.reload(true);
                }
              });
          return
        default:
          createPopupReportList(store,
              ["Не удалось поделиться заключением!", <br key={"createPopupReportList"} />, "Пожалуйста, попробуйте позднее."],
              "O", {});
      }
    } catch(error) {
      console.log(error)
    }
  }
  // Метод для открытия заключения в новой вкладке в виде PDF
  async function getPDFinNewTab (pk) {
    try {
      let response = await fetch(`${props.url}/examinations/reports/${pk}/download`,{
        method:'GET',
        headers: {
          "Authorization": `Token ${props.userKey}`,
        }})
      switch (response.status) {
        case 200:
          let myBlob = await response.blob()
          let url = URL.createObjectURL(myBlob)
          window.open(url);
          window.URL.revokeObjectURL()
          return
        case 401:
          createPopupReportList(store,
              ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
              "O", {
                "O": () => {
                  document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.location.reload(true);
                }
              });
          return
        default:
          createPopupReportList(store,
              ["Не удалось выполнить открытие PDF!", <br key={"createPopupReportList"} />, "Пожалуйста, попробуйте позднее."],
              "O", {});
      }
    } catch(error) {
      console.log(error)
    }
  }

  async function downloadWord (pk) {
    try {
      let response = await fetch(`${props.url}/examinations/reports/${pk}/download-word`,{
        method:'GET',
        headers: {
          "Authorization": `Token ${props.userKey}`,
        }})
      switch (response.status) {
        case 200:
          let myBlob = await response.blob();
          var url = window.URL.createObjectURL(myBlob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = "report.docx";
          a.click();
          window.URL.revokeObjectURL(url);
          return;
        case 401:
          createPopupReportList(store,
              ["Произошла ошибка авторизации!", <br key={"createPopupReportList_"} />, "Пожалуйста, попробуйте перезайти в систему."],
              "O", {
                "O": () => {
                  document.cookie = "userKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.cookie = "userFio= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.cookie = "userMail= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
                  document.location.reload(true);
                }
              });
          return
        default:
          createPopupReportList(store,
              ["Не удалось скачать WORD!", <br key={"createPopupReportList"} />, "Пожалуйста, попробуйте позднее."],
              "O", {});
      }
    } catch(error) {
      console.log(error)
    }
  }

  const generateCategory = () => {
    return (
        <div className="blockContainer">
          <div
              className={`blockSelector ${
                  store.getState().editorState.allActive ? "activeCategory" : ""
                  }`}
          >
            {props.categoryArray.map((category, index) => {
              return (
                  <div key={"activeCategory_" + index}
                       className={
                         !store.getState().editorState.allActive
                             ? category.visible
                             ? "activeCategory"
                             : ""
                             : ""
                       }
                       onClick={() => {
                         props.setAcitveCategory(index);
                       }}
                  >
                    <div />
                    <span>{category.title}</span>
                  </div>
              );
            })}
          </div>
        </div>
    );
  };

  const loadGeneratedSentenceText = (firstIndex, secondIndex, arrIndex) => {

    let sentence = store.getState().editorState.
        categoryArray[firstIndex].
        paragraph[secondIndex].
        matrixArray[arrIndex];

    $.ajax({
          type: "POST",
          url: `${props.url}/examinations/reports/generate-sentence`,
          contentType: "application/json",
          crossDomain: true,
          data: JSON.stringify(sentence),
          headers: {
            "Authorization": `Token ${props.userKey}`
          },
          success: function (data) {
            props.updateGeneratedSentenceText(firstIndex, secondIndex, arrIndex, data.message);
          },
          error: function (data) {
            console.log("Error! " + data);
          }
        }
    );
  };

  const generateControls = (data, firstIndex, secondIndex) => {
    return (
        <div>
          {data.matrixArray.map((arr, arrIndex) => {
            if (arr.status === "R") { return false; }
            return (
                <div key={arr.pk < 0 ? arr.pk - arrIndex : arr.pk} className={`objectsContainer ${data.type === "M" ? "bottomBorder" : ""}`}>
                  {
                    arr.values.map((matrix, i) => {
                      let tmpObject = data.values.find(value => matrix.template === value.pk);
                      if (tmpObject === undefined) { return false; }

                      if (tmpObject.type === 1 && tmpObject.view_type === "R") {
                        return (
                            <form key={tmpObject.pk}>
                              {tmpObject.choices.map(choice => {
                                return (
                                    <label key={`customRadio_${tmpObject.pk}_${choice.pk}`}>
                                      <input
                                          className="customRadio"
                                          type="radio"
                                          onClick={() => {
                                            props.changeRadio(firstIndex, secondIndex, arrIndex, i, choice.pk);
                                            loadGeneratedSentenceText(firstIndex, secondIndex, arrIndex);
                                          }}
                                          name={tmpObject.pk}
                                          defaultChecked={choice.pk === data.matrixArray[arrIndex].values[i].selected_choice ? true : false}
                                      />
                                      <span>{choice.label}</span>
                                    </label>
                                );
                              })}
                            </form>
                        );
                      }

                      if (tmpObject.type === 1 && tmpObject.view_type === "D") {
                        return (
                            <form key={tmpObject.pk} >
                              <div className="customSelect">
                                <select onChange={(e) => {
                                  props.changeRadio(firstIndex, secondIndex, arrIndex, i, Number(e.target.value));
                                  loadGeneratedSentenceText(firstIndex, secondIndex, arrIndex);
                                }}
                                        value={data.matrixArray[arrIndex].values[i].selected_choice}>
                                  {tmpObject.choices.map(choice => {
                                    return <option key={`customSelect_${tmpObject.pk}_${choice.pk}`}
                                                   value={choice.pk}
                                    >
                                      {choice.label}
                                    </option>;
                                  })}
                                </select>
                              </div>
                            </form>
                        );
                      }

                      if (tmpObject.type === 0) {
                        return (
                            <form key={tmpObject.pk}>
                              {tmpObject.choices.map(choice => {
                                let index = arr.values[i].selected_choices.indexOf(choice.pk);
                                return (
                                    <label key={`label_${tmpObject.pk}_${choice.pk}`}>
                                      <input
                                          defaultChecked={index >= 0 ? true : false}
                                          className="customCheckbox"
                                          value={choice.pk}
                                          type="checkbox"
                                          name={data.title}
                                          onChange={() => {
                                            props.changeCheck(firstIndex, secondIndex, arrIndex, i, choice.pk, data.message);
                                            loadGeneratedSentenceText(firstIndex, secondIndex, arrIndex);
                                          }}
                                      />
                                      <span>{choice.label}</span>
                                    </label>
                                );
                              })}
                            </form>
                        );
                      }
                      else {
                        return (
                            <form key={`Label_${tmpObject.pk}_${i}`}>
                              <div className="inpText">
                                <span>{tmpObject.label}</span>
                                <input type="text" value={matrix.text}
                                       style={{width: widthInPixels(matrix.text) + 'px'}}
                                       onInput={(e) => e.target.style.width=(widthInPixels(e.target.value)) + 'px'}
                                       onChange={(e) => {
                                         props.changeTextControls(firstIndex, secondIndex, arrIndex, i, e.target.value);
                                         loadGeneratedSentenceText(firstIndex, secondIndex, arrIndex);
                                       }} />
                                <span>{tmpObject.suffix}</span>
                              </div>
                            </form>
                        );
                      }
                    })
                  }
                  {data.type === "M" && data.matrixArray ? (
                      <div
                          onClick={() => {
                            props.deleteObjects(firstIndex, secondIndex, arrIndex);
                          }}
                          className="AoDButton Delete"
                      >
                        <span>&ndash; Удалить</span>
                      </div>
                  ) : null}
                </div>
            );
          })}
        </div>
    );
  };

  function widthInPixels(text) {
    return (text.length + 1) * 8;
  }

  const setCursor = (currentPos) => {
    document.getElementById("reportTextArea").innerHTML = document.getElementById("reportTextArea").innerText;
    var root = document.getElementsByClassName("mainTextColumn");
    const range = document.createRange();
    //debugger;
    range.setStart(root[0].getElementsByTagName("div")[0].firstChild, currentPos);
    range.setEnd(root[0].getElementsByTagName("div")[0].firstChild, currentPos);
    range.collapse(true);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  };

  const checkSearchStatus = () => {
    if (store.getState().leftMenu.search.status === false) {
      const entities = new Entities();
      return entities.encode(store.getState().editorState.generatedText);
    }
    else {
      const entities = new Entities();
      let foundText = store.getState().editorState.generatedText;
      let searchText = decodeURIComponent(store.getState().leftMenu.search.text);
      let tmpStr = foundText.replace(new RegExp(searchText, 'g'), "!@!");
      let tmpEncodeStr = entities.encode(tmpStr);
      let finalStr = tmpEncodeStr.replace(new RegExp('!@!', 'g'), "<span class='highlightText'>" + entities.encode(searchText) + "</span>");
      return finalStr;
    }
  }

  function textAreaAdjust(element) {
    element.target.style.height = "1px";
    element.target.style.height = (25+element.target.scrollHeight)+"px";
  }

  const generateColumns = () => {
    let lenth = props.categoryArray.length;
    return props.categoryArray.map((category, index) => {
      if (index === lenth - 1) {
        return category.visible ? (
            <div className="test reportTextContainer" key={"category_" + category.pk}>
              <div>
                <span className="categoryHeader reportTextHeader">Заключение</span>
                <div
                    className="fastInsert"
                    onClick={() => {
                      props.toogleFastInsert();
                    }}
                >
                  <span>Быстрая вставка</span>
                </div>
                <div className="mainTextColumn">
                  <textarea
                      autoComplete="off"
                      id="reportTextArea"
                      onLoad={(e) => {
                        checkSearchStatus();
                      }}
                      onKeyDown={function (e) {
                        /* if (e.key === "Enter") {
                          debugger
                          const currentPos = window.getSelection().anchorOffset;
                          //const test = e.target.getSelection().anchorOffset;
                          console.log(currentPos)
                          //let tmp = e.target.innerHTML;
                          setTimeout(function () {
                            setCursor(currentPos + 1);
                          }, 0);
                        } */
                        if (e.key === "Tab") {
                          //const currentPos = window.getSelection().anchorOffset;
                          //e.target.innerHTML = (e.target.innerText.substring(0, currentPos) + "    " + e.target.innerText.substring(currentPos, e.target.innerText.length)).replace(/<br>/g, "");
                          //e.preventDefault();
                          //setCursor(currentPos + 4);
                        }
                      }}
                      className="simpleTextArea"
                      value={props.generatedText}
                      onChange={(e) => { props.changeReportText(e.target.value); }}
                      onKeyUp={(e) => { textAreaAdjust(e); }}
                  >
                  </textarea>
                </div>
                <div className="buttonsBlock">
                  <div onClick={() => { saveReport(); }}>
                    <div>{workIcons("save")}</div>
                    <div>
                      <span>{props.templatePK != null ? "Сгенерировать заключение" : "Сохранить"}</span>
                    </div>
                  </div>
                  <div onClick={() => { saveReport("share"); }}>
                    <div>{workIcons("shareIcon")}</div>
                    <div>
                      <span>Ссылка на заключение</span>
                    </div>
                  </div>
                  <div onClick={() => { saveReport("download_pdf"); }}>
                    <div>{workIcons("openTabIcon")}</div>
                    <div>
                      <span>Скачать PDF</span>
                    </div>
                  </div>
                  <div onClick={() => { saveReport("download_word"); }}>
                    <div>{workIcons("openTabIcon")}</div>
                    <div>
                      <span>Скачать WORD</span>
                    </div>
                  </div>
                  <div onClick={() => {
                    createPopupReportList(store, [""], "C_SaP", { "sAs": () => { } }, "default", "saveAndSend");
                  }}>
                    <div>{workIcons("savecopy")}</div>
                    <div>
                      <span>Отправить на почту</span>
                    </div>
                  </div>

                  <div>
                    <div>{workIcons("createcopy")}</div>
                    <div onClick={() => {
                      if (store.getState().editorState.reportPK) {
                        copyReport();
                      } else {
                        createPopupReportList(store,
                            ["Скопировать можно только уже существующее заключение!"],
                            "O", {});
                      }
                    }}>
                      <span>Создать копию</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ) : null;
      }
      if (index === lenth - 2 && category.visible) {
        if (store.getState().editorState.isShared === false || store.getState().editorState.imageArray.length > 0) {
          return (
            <div className="test" key={"category_" + index}>
              <span className="categoryHeader categoryHeaderForReport">{category.title}</span>
              <div>

                <InsertImgBtn
                    showGallery={index => {
                      setGalleryImgIndex(index);
                    }}
                />
              </div>
            </div>
          );
        } else {
          return null;
        }
      }
      return category.visible ? (
          <div className="test" key={"category_" + index}>
            <span className="categoryHeader categoryHeaderForReport">{category.title}</span>
            <div className="test2">
              {category.paragraph.map((subparagraph, i) => {
                if (subparagraph.values.length === 0) return "";
                return (
                    <div key={"subColumn_" + i} className="subColumn">
                      <span>{subparagraph.title}</span>
                      {generateControls(subparagraph, index, i)}
                      {subparagraph.type === "M" ? (
                          <div
                              onClick={() => {
                                props.addObjects(index, i);
                                let lastMultipleSentence = props.categoryArray[index].paragraph[i].matrixArray.length - 1;
                                loadGeneratedSentenceText(index, i, lastMultipleSentence);
                              }}
                              className="AoDButton Add"
                          >
                            <span>+ Добавить</span>
                          </div>
                      ) : (
                          ""
                      )}

                      <div className="reportSentenceGeneratedText">
                        {subparagraph.matrixArray.length > 0 ? subparagraph.matrixArray.map(sentence => sentence.generatedText).join(" ") : ""}
                      </div>
                    </div>
                );
              })}
            </div>
          </div>
      ) : null;
    });
  };

  return (
      <div className="reportEditorContainer" >
        {galleryImgIndex !== null && <ImageGallery imageData={props.editor.imageArray} currentImage={galleryImgIndex} close={() => { setGalleryImgIndex(null) }} />}
        {props.fastInsertState.show && <FastInsert />}
        <div className="topHeader">
          <span>{props.editor.templatePK ? "Заключение по шаблону" : "Текстовое заключение"}</span>
        </div>
        <div className="reportDataHeader">
          <TextInputCustom
              type="date"
              inputHeader="Дата исследования"
              inputValue={store.getState().editorState.metaData[0]}
              changeText={props.changeInputText}
              index={0}
          />
          <TextInputCustom
              type="text"
              inputHeader="Имя пациента"
              inputValue={store.getState().editorState.metaData[1]}
              changeText={props.changeInputText}
              index={1}
          />
          <TextInputCustom
              type="date"
              inputHeader="Дата рождения"
              inputValue={store.getState().editorState.metaData[2]}
              changeText={props.changeInputText}
              index={2}
          />
          <div className="textInput noBorder">
            <span>Область исследования</span>
            <input className="nonEditableInput" readOnly contentEditable="false" type="text"
                   value={store.getState().editorState.fieldOfStudy}/>
          </div>
        </div>
        <div className="editorArea">
          <div className="editorParts">
            <div>{generateColumns()}</div>
          </div>
          {/*<div className="controlPanel">
            <div>
              <label>
                <input
                    className="customCheckbox"
                    type="checkbox"
                    value="openAll"
                    checked={store.getState().editorState.allActive}
                    onChange={() => {
                      props.setAcitveAllCategory();
                    }}
                />
                <span>Развернуть все</span>
              </label>
            </div>
            {generateCategory()}
            <div className="buttonsBlock">
              <div onClick={() => { saveReport(false); }}>
                <div>{workIcons("save")}</div>
                <div>
                  <span>{props.editor.isShared ? "Скопировать" : "Сохранить"}</span>
                </div>
              </div>
              <div onClick={() => { saveReport(true); }}>
                <div>{workIcons("openTabIcon")}</div>
                <div>
                  <span>Сохранить и открыть</span>
                </div>
              </div>
              <div onClick={() => {
                createPopupReportList(store, [""], "C_SaP", { "sAs": () => { } }, "default", "saveAndSend");
              }}>
                <div>{workIcons("savecopy")}</div>
                <div>
                  <span>Сохранить и отправить</span>
                </div>
              </div>

              <div>
                <div>{workIcons("createcopy")}</div>
                <div onClick={() => {
                  if (store.getState().editorState.reportPK) {
                    copyReport();
                  } else {
                    createPopupReportList(store,
                        ["Скопировать можно только уже существующее заключение!"],
                        "O", {});
                  }
                }}>
                  <span>Создать копию</span>
                </div>
              </div>
            </div>
          </div>*/}
        </div>
      </div >
  );
}

const mapDispatchToProps = {
  setAcitveCategory,
  setAcitveAllCategory,
  changeInputText,
  changeReportText,
  parseData,
  addObjects,
  deleteObjects,
  toogleFastInsert,
  test,
  changeTextControls,
  changeRadio,
  changeCheck,
  updateGeneratedSentenceText,
  saveMD5,
  saveMD5controls,
  saveAdditionalMD5,
  createPopupReportList,
  onLoadReport,
  selectCurrentReport
};
const mapStateToProps = state => {
  return {
    url: state.settings.url,
    userKey: state.settings.userKey,
    reportText: state.editorState.generatedText,
    reportData: state.editorState.reportData,
    templatePK: state.editorState.templatePK,
    categoryArray: state.editorState.categoryArray,
    categoryArray2: state.editorState.categoryArray[0],
    editor: state.editorState,
    searchText: state.leftMenu.search.text,
    fastInsertState: state.fastInsertState,
    generatedText: state.editorState.generatedText
  };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportEditor);